import { Box, Modal } from "@mui/material";
import React, { useEffect } from "react";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { SBox, SButton, STypographyText, STypography } from "./style.jsx";

function Message({ openMessage, handleClose }) {

  return (
    <Modal

      open={openMessage}
      onClose={handleClose}
    >
      <SBox>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CheckCircleOutlineRoundedIcon
            sx={{ fontSize: 70, color: "var(--blue)", marginRight: "16px" }}
          />
          <STypography>A sua mensagem foi submetida com sucesso!</STypography>
        </Box>
        <STypographyText>
          Em breve, iremos entrar em contacto consigo.
        </STypographyText>
        <SButton variant="contained" onClick={handleClose} >
          Fechar
        </SButton>
      </SBox>
    </Modal>
  );
}

export default Message;
