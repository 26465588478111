import { Button, Typography } from '@mui/material';
import styled from 'styled-components';

export const CustomTitle = styled(Typography)`
    &&{
        font-size: 32px;
        font-family:Poppins Semibold;
        color:var(--primary);
        margin-bottom: 32px;
    }
`;

export const CustomSubTitle = styled(Typography)`
    &&{
        font-size: 24px;
        font-family:Poppins Semibold;
        color:var(--primary);
        margin-bottom: 16px;
    }
`;

export const CustomText = styled(Typography)`
    &&{
        font-size: 16px;
        font-family:Poppins Regular;
        color:var(--dark);
    }
`;

export const CustomButton = styled(Button)`
    &&{
    position: sticky;
    bottom: 0;
    top: 200px;
    font-size: 20px;
    font-family: Poppins Medium;
    color: var(--primary);
    background-color: var(--light);
    border: 1px solid var(--primary);
    width: max-content;
    padding: 8px 14px;
    box-sizing: border-box;
    text-transform: none;
    min-height: 46px;
    height: 46px;
    border-radius: 7px;
    &:hover {
      background-color: var(--primary);
      color: var(--light);
    }
`;