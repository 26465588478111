import { Modal } from "@mui/material";
import React, {useState} from "react";
import {
  SContainerContact,
} from "./style.jsx";
import ContactForm from "./contactForm.jsx";
import Message from "../submitmessages/message.jsx";
function Modalcontact({ openModal, handleClose }) {

  const [openMessage, setOpenMessage] = useState(false);

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  return (
    <>
      <Modal
        BackdropProps={{
          invisible: true
        }}
        open={openModal}
        onClose={handleClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: " 100%",
        }}
      >
        <SContainerContact maxWidth="lg">
          <ContactForm handleClose={handleClose} />
        </SContainerContact>
      </Modal>
      {openMessage && <Message openMessage={openMessage} handleClose={handleClose} />}
    </>

  );
}

export default Modalcontact;
