import { Box, Button, Typography } from "@mui/material";
import styled from "styled-components";

export const SBox = styled(Box)`
  background: var(--white);
  border-radius: 8px;
  padding: 30px;
  max-width: 500px;
  box-shadow: 0px 0px 6px var(--green);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const STypography = styled(Typography)`
  && {
    font-size: 24px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--blue);
    text-align: left;
  }
`;

export const STypographyText = styled(Typography)`
  && {
    font-size: 24px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--dark);
    text-align: left;
  }
`;

export const SButton = styled(Button)`
  && {
    padding: 10px 26px;
    background: var(--blue);
    border-radius: 8px;
    color: var(--white);
    font-size: 20px;
    font-weight: 500;
    font-family: Poppins Medium;
    text-transform: none;
    &:hover {
      background: var(--blue);
    }
  }
`;
