import {
  Button,
  Checkbox,
  Container,
  FormLabel,
  Grid,
  Input,
  Select,
  Typography,
} from "@mui/material";
import styled from "styled-components";


export const SContainerContact = styled(Container)`
  && {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    padding: 32px;
    backdrop-filter: blur(30px);
  }
`;

export const GridContainer1 = styled(Grid)`
  && {
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px var(--primary);
    background: var(--light);
    display: flex;
    flex-direction: column;
    gap: 32px;
    @media (max-width: 600px) {
      display: none;
    }
  }
`;

export const GridContainer2 = styled(Grid)`
  && {
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px var(--primary);
    background: var(--light);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const STypographyContactTitle = styled(Typography)`
  && {
    font-size: 30px;
    font-weight: 600;
    font-family: Poppins Semibold;
    color: var(--primary);
  }
`;

export const STypographyContactSubText = styled(Typography)`
  && {
    font-size: 26px;
    font-weight: 300;
    font-family: Poppins Light;
    color: var(--dark);
  }
`;

export const STypographyContactText = styled(Typography)`
  && {
    font-size: 20px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--dark);
  }
`;

export const SLabel = styled(FormLabel)`
  && {
    font-size: 20px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--primary);
  }
`;

export const SInput = styled(Input)`
  && {
    width: 100%;
    height: 36px;
    padding-left: 12px;
    margin: 0;
    margin-bottom: 20px;
    border-radius: 5px;
    background: rgba(223, 222, 222, 0.33);
    text-decoration: none;
  }
`;

export const SSelect = styled(Select)`
  width: 100%;
  height: 36px;
  border-radius: 5px;
  background: rgba(223, 222, 222, 0.33);
  font-size: 20px;
  font-weight: 300;
  font-family: Poppins Light;
  color: var(--primary);
`;

export const SCheckbox = styled(Checkbox)`
  && {
    width: 22px;
    height: 22px;
    border-radius: 5px;
    color: var(--light);
    border: 1px solid var(--primary);
    margin-right: 10px;
  }
`;

export const STypographyCheck = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--dark);
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
`;

export const SButtonSend = styled(Button)`
  && {
    border-radius: 8px;
    background: var(--primary);
    width: 147px;
    height: 36px;
    color: var(--light);
    font-size: 20px;
    font-family: Poppins Regular;
    font-weight: 400;
    &:hover {
      background: var(--primary);
    }
    @media (max-width: 600px) {
      margin-top: 16px;
      width: 100%;
    }
  }
`;
